import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { concatMap, map, switchMap, tap } from 'rxjs/operators';

import { JwtHelperService } from '@auth0/angular-jwt';

import { Theme } from '@shared/index';


/**
 * Service for managing users.
 */
@Injectable({ providedIn: 'root' })
export class ThemeService {

  private themingUrl = `${this.serviceUrl}/api/theme/company`;
	private httpOptions = {
						    headers: new HttpHeaders({ 'Content-Type': 'multipart/form-data' })};

  themeWrapper = document.querySelector('body');

  constructor(@Inject('SERVICE_URL') private serviceUrl: string,
              private http: HttpClient,
              private helper: JwtHelperService) { }


  /**
   * Get Company Id
   */
  get companyId() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    const data = this.helper.decodeToken(user.token);
    return data.IdCompany;
  }

  public get getTheme() {
    return JSON.parse(localStorage.getItem('theme'));
  }

  fetchTheme(): Observable<any> {
    const companyId = this.companyId;
    localStorage.setItem('companyId', companyId);
    
    return this.http.get(`${this.themingUrl}/${companyId}`).pipe(
      switchMap(data => {
        if (data) {
          localStorage.setItem('theme', JSON.stringify(data));
          this.setTheme(data);
          return of(data);  // Emit the main theme data and complete
        } else {
          console.log('No theme found, fetching default theme...');
          return this.defaultTheme(companyId).pipe(
            map(defaultData => {
              if (defaultData) {
                localStorage.setItem('theme', JSON.stringify(defaultData));
                this.setTheme(defaultData);
              }
              return defaultData; // Emit default theme data as the result
            })
          );
        }
      })
    );
    
  }

  defaultTheme(companyId: number): Observable<any>{
    return this.http.get(this.serviceUrl +'/api/theme/defualtTheme/'+companyId);
  }

	saveTheme(data: any): Observable<any> {
		return this.http.post<Theme>(this.serviceUrl + '/api/theme/save', data);
	}


  submitCompanyLogos(data: any){
    return this.http.post<any>(this.serviceUrl + "/api/theme/submitCompanyLogos", data);
  }
  postTemplate(data: any): Observable<any>{
    return this.http.post<Theme>(this.serviceUrl + '/api/theme/postTemplate', data);
  }

  getThemes(): Observable<any> {
    return this.http.get(this.serviceUrl + '/api/theme/all');
  }

  getTemplates(): Observable<any> {
    return this.http.get(this.serviceUrl + '/api/theme/getTemplates');
  }

  setTheme(palette: any) {
    // Background
    this.themeWrapper.style.setProperty('--background-primary', palette.backgroundPrimary);
    this.themeWrapper.style.setProperty('--background-secondary', palette.backgroundSecondary);
    this.themeWrapper.style.setProperty('--background-default', palette.backgroundDefault);
    this.themeWrapper.style.setProperty('--background-tertiary', palette.backgroundTertiary);
    this.themeWrapper.style.setProperty('--background-quaternary', palette.backgroundQuaternary);

    // Fonts
    this.themeWrapper.style.setProperty('--font-primary', palette.fontPrimary);
    this.themeWrapper.style.setProperty('--font-secondary', palette.fontSecondary);
    this.themeWrapper.style.setProperty('--font-default', palette.fontDefault);
    this.themeWrapper.style.setProperty('--font-tertiary', palette.fontTertiary);

    // Buttons bg
    this.themeWrapper.style.setProperty('--button-primary', palette.buttonPrimary);
    this.themeWrapper.style.setProperty('--button-secondary', palette.buttonSecondary);
    this.themeWrapper.style.setProperty('--button-default', palette.buttonDefault);

    // Buttons font
    this.themeWrapper.style.setProperty('--button-primary-font', palette.buttonPrimaryFont);
    this.themeWrapper.style.setProperty('--button-secondary-font', palette.buttonSecondaryFont);


    // Input states bg
    this.themeWrapper.style.setProperty('--normal-state', palette.normalStateBackground);
    this.themeWrapper.style.setProperty('--saved-state', palette.savedStateBackground);
    this.themeWrapper.style.setProperty('--submit-state', palette.submitStateBackground);

    // Table bg
    this.themeWrapper.style.setProperty('--table-primary', palette.tablePrimary);
    this.themeWrapper.style.setProperty('--table-secondary', palette.tableSecondary);
    this.themeWrapper.style.setProperty('--table-default', palette.tableDefault);
    this.themeWrapper.style.setProperty('--table-tertiary', palette.tableTertiary);
    this.themeWrapper.style.setProperty('--table-quaternary', palette.tableQuaternary);

    // Badge bg
    this.themeWrapper.style.setProperty('--badge-primary', palette.badgePrimary);

  }


  toggleTimeLogContractors(isContractors: any) {
    return this.http.get(this.serviceUrl + '/api/theme/toggleTimeLogContractors?isContractors=' + isContractors + '&companyId=' + this.companyId);
  }

  toggleContractorHide(isContractor: any){
    return this.http.get(this.serviceUrl + '/api/theme/toggleContractorHide?isContractor=' + isContractor + '&companyId=' + this.companyId);
  }

}
