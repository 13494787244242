import { Injectable, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { Subject } from 'rxjs';
import { switchMap, map } from 'rxjs/operators';

import { JwtHelperService } from '@auth0/angular-jwt';

import { Error } from '@core/shared/models/error.model';

import { ThemeService } from '@shared/index';


@Injectable({ providedIn: 'root' })
export class AuthService {

  private authenticateUrl = `${this.serviceUrl}/api/user`;

  errors$: Subject<Error> = new Subject<Error>();
  getErrors$ = this.errors$.asObservable();

  constructor(@Inject('SERVICE_URL') private serviceUrl: string,
              private jwtHelper: JwtHelperService,
              private http: HttpClient,
              private router: Router,
              private themeService: ThemeService) { }

  /**
   * Get current user role
   */
  get role() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser'));
    return currentUser.permission.abbrv;
  }

  /**
   * Get current user info
   */
  get user() {
    return JSON.parse(localStorage.getItem('currentUser'));
  }

  /**
   * Get current user permission
   */
  get permission() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return user && user.permission.abbrv;
  }

  get isContractor() {
    const user = JSON.parse(localStorage.getItem('currentUser'));
    return user && user.isContractor;
  }

  /**
  * POST method for creating a new user.
  */
  login({loginName, password}) {
    return this.http
      .post<any>(this.authenticateUrl + '/authenticate', { loginName: loginName, password: password })
      .pipe(
        map(user => {
          if (user && user.token) localStorage.setItem('currentUser', JSON.stringify(user));
          return user;
        }),
        switchMap(() => this.themeService.fetchTheme())
      );
  }

  passwordsHash() {
    return this.http.get<any>(this.authenticateUrl + '/create_all_user_hash');
  }

  /**
   * Allows the logout of the app
   */
  logout() {
    //localStorage.removeItem('currentUser');
    localStorage.clear();
    //localStorage.removeItem('theme');
    this.router.navigate(['/login']);
  }

  /**
   * Check if user is autenticated
   */
  isAuthenticated(): boolean {
    const token = localStorage.getItem('token');

    return !this.jwtHelper.isTokenExpired(token);
  }


  updateToken(companyId: any) {
    return this.http.get<any>(this.authenticateUrl + '/update_token/' + companyId);
  }
}
