import { UserCompany } from '@shared/models/user-company.model';
import { UserTask } from '@shared/models/user-task.model';
import { Permission } from '@shared/models/permission.model';
import { ManagerProject } from '@dashboard/shared';


export class User {
  id?: number;
  loginName?: string;
  password?: string;
  confirmPassword?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  startDate?: string;
  totalVacationDays?: number;
  permission?: Permission;
  permissionId?: string;
  isEditing?: boolean;
  isSelectedForVacation?: boolean;
  archived?: boolean;
  registerToken?: string;
  registerTokenUsed?: boolean;
  forgotPasswordToken?: string;
  forgotPasswordTokenUsed?: boolean;
  active?: boolean;
  weeklyHours?: number;
  timeEntriesAccess?: boolean;
  UserCompany?: UserCompany[];
  userTask?: UserTask[];
  Timelog?: UserTask[];
  managerProjects?: ManagerProject[];
  emailNotification: boolean;
  fullName?: string;
  gamificationPoints?: number;
  isGamificationReports?: boolean;
  isGamificationTimeLogs?: boolean;
  isContractor?:boolean;
  timeZone?: string;
  dateBirth?: Date;
  timeIn?: string;
  timeOut?: string;
  role?: string;
}

