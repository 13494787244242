import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { User, Permission } from '@shared/index';
import { StoreService } from '@shared/store/store.service';


/**
 * Service for managing users.
 */
@Injectable({ providedIn: 'root' })
export class UserService {

  private userUrl = `${this.serviceUrl}/api/user`;

  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  constructor(@Inject('SERVICE_URL') private serviceUrl: string,
              private http: HttpClient,
              private store: StoreService) { }


  /**
  * GET method for retrieving all users.
  * @param archived: true if archived, false if not archived
  * @param permission: permision of the user. User manager or admin.
  * @returns: list with all users.
  */
  getAllUsers(archived: boolean, permission?: number,isContractor?: boolean): Observable<User[]> {
    let url = archived !== null ? `${this.userUrl}?archived=${archived}` : `${this.userUrl}?archived=`;

    if (permission) url += `&permission=${permission}`;

    if(isContractor != null) url += `&isContractor=${isContractor}`;

    return this.http
      .get<User[]>(url)
      .pipe(tap(next => this.store.set('employees', next)));
  }

  getAllEmployees(archived: boolean, permission?: number,isContractor?: boolean): Observable<User[]> {
    let url = archived !== null ? `${this.userUrl + '/employees'}?archived=${archived}` : `${this.userUrl}?archived=`;

    if (permission) url += `&permission=${permission}`;

    if(isContractor != null) url += `&isContractor=${isContractor}`;

    return this.http
      .get<User[]>(url)
      .pipe(tap(next => this.store.set('employees', next)));
  }

  /**
  * GET method for retrieving all permissions.
  * @returns: list with all permissions.
  */
  getAllPermissions(): Observable<Permission[]> {
    const url = `${this.userUrl}/permissions`;
    return this.http
      .get<Permission[]>(url)
      .pipe(tap(next => this.store.set('permissions', next)));
  }

  /**
   * POST method for creating a new user.
   * @param userModel: data of the user to create.
   * @returns: user created or null if there was an error.
   */
  createUser(userModel: User): Observable<User> {
    return this.http.post<User>(this.userUrl, userModel, this.httpOptions);
  }

  createNewUser(userModel: User): Observable<User> {
    return this.http.post<User>(this.userUrl + "/new", userModel, this.httpOptions);
  }

  /**
  * GET method to check if the user has token to register for registration screen.
  * @param token: token got from url
  * @returns: user models.
  */
  getRegisterUserToken(token: string): Observable<User> {
    const url = `${this.userUrl}/register?token=${token}`;
    return this.http.get<User>(url);
  }

  /**
   * GET method to get the email when recover the password
   * @param email: email adress to sent the email
   */
  getEmailRecoverPassword(email: string): Observable<User> {
    const url = `${this.userUrl}/recoverpasswordemail?email=${email}`;
    return this.http.get<User>(url);
  }

  /**
   * GET method to check the token on the recover pasword functionality
   * @param token: token to recover the password.
   */
  getRecoverPasswordToken(token: string): Observable<User> {
    const url = `${this.userUrl}/recoverpasswordtoken?token=${token}`;
    return this.http.get<User>(url);
  }

  /**
   * PUT method for updating an existing user.
   * @param userModel: data of the user for updating.
   * @returns: user updated or null if there was an error.
   */
  updateUser(userModel: User): Observable<User> {
    return this.http.put<User>(this.userUrl, userModel, this.httpOptions);
  }

  /**
   * PUT method that allows to update the User Registration
   * @param userModel models that manage te Users.
   */
  updateUserRegistration(userModel: User): Observable<User> {
    const url = `${this.userUrl}/updateuserregistration`;
    return this.http.put<User>(url, userModel, this.httpOptions);
  }

  /**
   * PUT method to update user's password.
   * @param userModel: data of the user to create.
   * @returns: user created or null if there was an error.
   */
  updateRecoverPasswordEmail(userModel: User): Observable<User> {
    const url = `${this.userUrl}/resetpassword`;
    return this.http.put<User>(url, userModel, this.httpOptions);
  }

  /**
   * DELETE method for deleting an existing user.
   * @param userId: id of the user to be deleted.
   * @returns: deleted user or null if there was an error.
   */
  deleteUser(userId: number): Observable<User> {
    const url = `${this.userUrl}/${userId.toString()}`;
    return this.http.delete<User>(url);
  }


  getUserById(userId: number): Observable<User> {
  	const url = `${this.userUrl}/id/${userId.toString()}`;
	return this.http.get<User>(url);
  }

  checkEmail(email: String): Observable<any> {
    var body = {email : email};
    return this.http.post(this.userUrl+'/emailCheck',body, this.httpOptions);
  }
  
    /**
   * POST method for creating a multiple new user.
   * @param userModel: array of data of the user to create.
   * @returns: user created or throw error.
   */
  createMultipleUser(userModel: User[]): Observable<User[]> {
    return this.http.post<User[]>(this.userUrl + '/create-multiple-user', userModel, this.httpOptions);
  }


  createPushNotification(token: string): Observable<any>{
    return this.http.get<string>(this.userUrl+ '/addPushNotification/' + token);
  }

  removePushNotification(token: string): Observable<any>{
    return this.http.get<string>(this.userUrl + '/removePushNotification/' + token);
  }

  getPushNotification(token: string): Observable<any>{
    return this.http.get<string>(this.userUrl + '/getPushNotification/' + token);
  }

  getCompanies(): Observable<any>{
    return this.http.get<string>(this.userUrl + '/userCompanies');
  }

  updateCompanie(companie: any): Observable<any>{
    return this.http.post<string>(this.userUrl + '/updateCompanies', companie);
  }

}
